import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import DropshippingBoxImgSource from 'images/blog-images/dropshipping-boxes.png';
import DropshippingImgSource from 'images/blog-images/dropshipping-image.png';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query DropshippingBlogImg {
      file(relativePath: { eq: "dropshipping.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="what you need to know about dropshipping"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="What You Need to Know About Dropshipping | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-what-you-need-know" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "What You Need to Know About Dropshipping | Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-what-you-need-know" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: DropshippingBoxImgSource,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="what-you-need-to-know-about-dropshipping"
        title="What You Need to Know About Dropshipping"
        date="2020-05-14"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <FirstTitle>What is Dropshipping?</FirstTitle>
            
            <p>
              Dropshipping is a retail method in which you do not keep products in stock. It is a term used in supply chain management, in which retailers do not maintain product inventory, but send customer orders and shipping information directly to the manufacturer or wholesaler, and the manufacturer or wholesaler reshippers the goods directly to the client. Most retailers in the retail business earn profits from the difference between wholesale and retail prices, but some direct shipping platforms earn a percentage of the commission that the manufacturer or wholesaler agrees to pay.
            </p>

            <Image src={DropshippingImgSource} alt="dropshipping image"/>

            <p>
              So as simple as possible, the above image shows the 4 steps in dropshipping.
            </p>

            <OrderedList>
              <li>
                You list products (supplier’s products) in your eCommerce store for customers to buy.
              </li>
              <li>
                The customer purchases products from your eCommerce store.
              </li>
              <li>
                You order the products that the customer paid for from your supplier.
              </li>
              <li>
                Your dropshipping supplier ships this item directly to your customer. So your customer receives the item that they have ordered from you.
              </li>
            </OrderedList>

            <h5>​Advantages & Disadvantages of Dropshipping</h5>

            <h5>Advantages</h5>

            <UnorderedList>
              <li>
                Easy to Set Up <br />
                This business model is easy to understand and implement even if you are a beginner in the eCommerce industry. You can easily set up your store just in a few simple steps. First, identify your products. Second, partner with a good third-party supplier and then set up your online store. Lastly, select some products and list them in your store for sale.
              </li>
              <li>
                No Inventory Costs <br />
                When you are starting with dropshipping, you are not laying out any of your money for inventory. All you need is approvals from suppliers to have products. You only pay for items after you sell them and there are no warehouse costs.
              </li>
              <li>
                Flexible location <br />
                A dropshipping business can be run from just about anywhere with an internet connection. As long as you can communicate with suppliers and customers easily, you can run and manage your business, because you are location independent.
              </li>
              <li>
                Easily Scalable <br />
                Another huge benefit of a dropshipping business is how easy it is to scale. For instance, if you are making $300,000 a year in sales from just one drop shipping store, it’s very easy to build more stores with similar results. And the best part is that you can do this over and over again.
              </li>
            </UnorderedList>

            <h5>
              Disadvantages
            </h5>

            <UnorderedList>
              <li>
                Low-Profit Margins <br />
                Low margins are the biggest disadvantage of operating in a highly competitive dropshipping vertical. Because you’re selling someone else’s products, profits are going to be less than if you created your products.
              </li>
              <li>
                No Inventory Control <br />
                You have no control over inventory. Let’s say you have one supplier whose product sells well on your website, you’re making great money with them. Well, what happens if that supplier runs out of stock? What if your drop shipping supplier sells out of those items?
              </li>
              <li>
                Limited customization and branding <br />
                Unlike custom-made products or print on demand, dropshipping does not give you a lot of control over the product itself. Usually, product dropshipped is designed and branded by the supplier.
              </li>
            </UnorderedList>

            <Image src={DropshippingBoxImgSource} className="no-top" alt="dropshipping boxes"/>

            <h5>
              Common Questions About Dropshipping
            </h5>

            <UnorderedList>
              <li>
                How much money can you make dropshipping? <br />
                On average, dropshipping margins range anywhere from 10%-30%. But this number can vary greatly depending on what type of products you sell.
              </li>
              <li>
                Is dropshipping a legitimate business? <br />
                Dropshipping is merely a fulfillment model which is used by many global retailers, and is completely legal. But since this is genuine, the most legal concerning areas of dropshipping is taxes and copyright infringement.
              </li>
              <li>
                Will I have to deal with customer problems? <br />
                Yes, you will have to deal with your customers. Your supplier does a lot for you (like storing and shipping your products), but you will be the one that will be in contact with your customers.
              </li>
            </UnorderedList>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;